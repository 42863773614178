import Vue from 'vue';
import { getExtensionFromString } from '~/utils/helpers';

// Storyblok Image Service https://www.storyblok.com/docs/image-service
Vue.filter('sbImageService', (filename, options = null) => {
  if (!filename) return filename;

  const path = filename.replace('//s3.amazonaws.com/a.storyblok.com/', '//a.storyblok.com/');
  const result = `${path}/m/${options ? options : ''}`;
  const extension = getExtensionFromString(path);

  return extension === 'svg' ? path : result;
});

// Get image size (width or height) from Storyblok's asset url
Vue.filter('sbImageSize', (src, side, fallback) => {
  const reg = /^\d+$/;
  const isFallback = reg.test(fallback + '');

  if (src && side) {
    const sizes = src.split('/')[5]?.split('x');
    
    if (sizes?.length === 2) {
      const width = sizes[0];
      const height = sizes[1];
      const isValid = reg.test(width) && reg.test(height);

      if (isValid) {
        if (side === 'width') {
          return width;
        }

        if (side === 'height') {
          return height;
        }
      }
    }
  }

  return isFallback ? fallback : null;
});

Vue.filter('minimumIntegerDigits', (value, minimum = 2) => {
  return value.toLocaleString('en-US', {
    minimumIntegerDigits: minimum,
    useGrouping: false,
  });
});

Vue.filter('currency', (value, space = ' ') => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
  });

  return formatter.format(value).replace(/,/g, space);
});

Vue.filter('number', (value, space = ' ') => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
  });

  return formatter.format(value).replace(/\.00/g, '').replace(/,/g, space);
});
