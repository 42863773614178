//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconArrow from '~/assets/img/icons/arrow.svg?inline';
import IconLink from '~/assets/img/icons/link.svg?inline';

export default {
  name: 'BaseBtn',
  components: {
    IconArrow,
    IconLink,
  },
  props: {
    type: {
      type: String,
      validator: type => ['button', 'submit', 'reset'].includes(type),
      default: 'button',
    },
    link: {
      type: [Object, String],
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pending: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    iconPrev: {
      type: String,
      default: null,
    },
    iconNext: {
      type: String,
      default: null,
    },
  },
  computed: {
    getComponent() {
      if (this.link) {
        return {
          is: 'BaseLinkWr',
          link: this.link,
        };
      }

      return {
        is: 'button',
        type: this.type,
        disabled: this.disabled || this.pending,
      };
    },
    getIconPrev() {
      return this.getIcon(this.iconPrev);
    },
    getIconNext() {
      return this.getIcon(this.iconNext);
    },
  },
  methods: {
    getIcon(icon) {
      switch(icon) {
        case 'arrow':
          return 'IconArrow';
        case 'link':
          return 'IconLink';
        default:
          return null;
      }
    },
    handleClick(e) {
      if (this.disabled || this.pending) return;
      this.$emit('click', e);
    },
  },
}
