import { useStoryblokApi } from "@storyblok/nuxt-2";

export const state = () => ({
  version: undefined,
  language: undefined,
  cv: undefined,
});

export const mutations = {
  setVersionAndLang(state, data) {
    state.version = data.version;
    state.language = data.language;
  },
  setStoryblokCacheVersion(state, cv) {
    state.cv = cv;
  },
};

export const actions = {
  async nuxtServerInit({ dispatch, commit }, { query, isDev }) {
    dispatch('getVersionAndLang', { query, isDev });
    await dispatch('getStoryblokCacheVersion');
  },
  getVersionAndLang({ commit }, { query, isDev }) {
    const { _storyblok } = query;
    const { locale, defaultLocale } = this.$i18n;
    const isPreview = !!(_storyblok && _storyblok !== '') || isDev;
    const version = isPreview ? 'draft' : 'published';
    const language = locale === defaultLocale ? 'default' : locale;

    commit('setVersionAndLang', { version, language });

    return { version, language };
  },
  async getStoryblokCacheVersion({ commit }) {
    try {
      const storyblokApi = useStoryblokApi();
      const { data } = await storyblokApi.get(`cdn/spaces/me?timestamp=${Date.now()}`);
      const cv = data?.space?.version;

      commit('setStoryblokCacheVersion', cv);

      return cv;
    } catch(err) {
      console.error("getStoryblokCacheVersion ERROR", err);
    }
  },
};
