//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconArrow from "~/assets/img/icons/arr-select.svg?inline";

export default {
  name: 'UiLangDropdown',
  components: {
    IconArrow,
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale);
    },
    currentLocale() {
      return this.$i18n.locales.find(i => i.code === this.$i18n.locale);
    },
  },
  methods: {
    handleToggle() {
      this.isActive = !this.isActive;
    },
    handleClose() {
      this.isActive = false;
    },
    handleSelect(item) {
      this.$emit('select', item.code);
      this.handleClose();
    },
  },
}
