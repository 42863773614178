var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-btn",class:{
    'base-btn--disabled': _vm.disabled,
    'base-btn--pending': _vm.pending,
    'base-btn--success': _vm.success,
  }},[_c('component',_vm._b({staticClass:"base-btn__button -t4-600 -g-d-if -g-ai-c -g-jc-c",class:{
      'disabled': _vm.disabled,
      'pending': _vm.pending,
      'success': _vm.success,
    },attrs:{"aria-label":!_vm.title && _vm.type === 'submit' ? _vm.$t('submit') : null},on:{"click":_vm.handleClick}},'component',_vm.getComponent,false),[_c('span',{staticClass:"base-btn__button-inner -g-d-f -g-ai-c -g-jc-c"},[(_vm.getIconPrev)?_c('span',{staticClass:"base-btn__arr base-btn__arr--prev -g-d-f -g-ai-c -g-jc-c"},[_c(_vm.getIconPrev,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.title)?_c('span',{staticClass:"base-btn__title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_vm._v(" "),(_vm.getIconNext)?_c('span',{staticClass:"base-btn__arr base-btn__arr--next -g-d-f -g-ai-c -g-jc-c"},[_c(_vm.getIconNext,{tag:"component"})],1):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.pending)?_c('BaseSpinner',{staticClass:"base-btn__spinner -g-d-f -g-ai-c -g-jc-c"}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.success)?_c('div',{staticClass:"base-btn__success -g-d-f -g-ai-c -g-jc-c"},[_vm._t("success")],2):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }