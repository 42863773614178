export const state = () => ({
  heroNumber: '',
  heroTimespan: 0,
});

export const mutations = {
  setNumber(state, value) {
    state.heroNumber = value;
    state.heroTimespan = Date.now();
  },
};
