import Vue from 'vue'
import {
  Dropdown,
} from 'floating-vue'

Vue.component('VDropdown', Dropdown)

// Vue.directive('close-popper', VClosePopper)
// Vue.component('VTooltip', Tooltip)
// Vue.component('VMenu', Menu)
// Vue.directive('tooltip', VTooltip)
