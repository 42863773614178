//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import debounce from "lodash/debounce";
import { CollapseTransition } from 'vue2-transitions';
import { isRichtextFilled } from '~/utils/helpers';

export default {
  name: 'LayoutHeader',
  components: {
    CollapseTransition,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
    info: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      isScrolled: false,
      destroyed: false,
      showMenu: false,
      heightMenu: 0,
      heightHeader: 0,
    }
  },
  computed: {
    ...mapState('page', {
      forceHideMenu: state => !!state.story?.content?.hide_header_menu,
    }),
    logoFilename() {
      return this.blok.logo?.filename;
    },
    isLinks() {
      return this.blok.links?.length;
    },
    locales() {
      return this.$i18n.locales;
    },
    isAddress() {
      return isRichtextFilled(this.info.address);
    },
    isSocialLinks() {
      return this.info.social_links?.length;
    },
  },
  methods: {
    handleClickLogo() {
      this.handleCloseMenu();

      if (this.$route.path === this.localePath('/')) {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    },
    handleToggleMenu() {
      this.showMenu = !this.showMenu;
    },
    handleCloseMenu() {
      this.showMenu = false;
    },
    onScroll() {
      const scrollStart = 0;
      const scrolled = document.body.scrollTop > scrollStart || document.documentElement.scrollTop > scrollStart;

      this.isScrolled = scrolled ? true : false;
    },
    onResize() {
      this.heightMenu = window.innerHeight;
      this.heightHeader = this.$refs.header.clientHeight;
    },
    handleScroll() {
      if (this.destroyed) return;

      this.onScroll();
    },
    handleResize() {
      if (this.destroyed) return;

      this.onResize();
      this.onScroll();
    },
  },
  created() {
    this.debounceHandleResize = debounce(this.handleResize, 150);
  },
  mounted() {
    this.$nextTick(() => {
      this.handleResize();
    });

    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.debounceHandleResize);
  },
  beforeDestroy() {
    this.destroyed = true;

    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.debounceHandleResize);
  },
};
