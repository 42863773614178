export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"uk","silentTranslationWarn":true,"messages":{"uk":{"homepage":"Головна","to-homepage":"На головну","field-required":"Обов'язкове поле","invalid-value":"Недійсне значення","invalid-email":"Недійсний формат електронної пошти","email":"Ваша електронна пошта","email-blacklist":"Друже, пробач але дуже багато розрахунків, дай відпочити"},"en":{"homepage":"Homepage","to-homepage":"To homepage","field-required":"Field is required","invalid-value":"Invalid value","invalid-email":"Invalid email format","email":"Your email","email-blacklist":"Friend, there are too many calculations, give it a rest"}}},
  vueI18nLoader: true,
  locales: [{"code":"uk","iso":"uk","name":"Укр"},{"code":"en","iso":"en","name":"Eng"}],
  defaultLocale: "uk",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: null,
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  BASE_URL: "https://easypeasy.com.ua",
  normalizedLocales: [{"code":"uk","iso":"uk","name":"Укр"},{"code":"en","iso":"en","name":"Eng"}],
  localeCodes: ["uk","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "}",
}

export const localeMessages = {}
