//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "RichtextBlocksImage",
  props: {
    attrs: {
      type: Object,
      required: true,
    },
  },
};
