import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=edf218e6&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=edf218e6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edf218e6",
  null
  
)

/* custom blocks */
import block0 from "./Header.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiLangDropdown: require('/app/components/ui/LangDropdown.vue').default,BaseLinkWr: require('/app/components/base/LinkWr.vue').default,UiHamburgerBtn: require('/app/components/ui/HamburgerBtn.vue').default,BaseRichtext: require('/app/components/base/Richtext.vue').default})
