//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isRichtextFilled } from '~/utils/helpers';

export default {
  name: 'LayoutFooter',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isAddress() {
      return isRichtextFilled(this.blok.address);
    },
    isSocialLinks() {
      return this.blok.social_links?.length;
    },
    isColumns() {
      return this.blok.columns?.length;
    },
    copyright() {
      const text = this.blok.copyright;
      const regex = /\$\{year\}/gm;
      const str = text ? text : '';
      const subst = new Date().getFullYear();

      return str.replace(regex, subst);
    },
  },
};
