import isEqual from "lodash/isEqual";

export const rewriteStorySlugs = {
  'home': '/',
  '/home': '/',
  '/home/': '/',
  '/en/home': '/en/',
  '/en/home/': '/en/',
  '/en': '/en/',
};

export function addEndTrailingSlash(value) {
  if (!value) return value;

  return value.endsWith('/') ? value : `${value}/`;
};

export function removeEndTrailingSlash(value) {
  if (!value) return value;

  const res = value.replace(/\/$/, '');

  return res.startsWith('/') ? res : `/${res}`;
};

export function getStoryLink(link) {
  const { linktype, cached_url, url } = link;

  if (!link) return null;
  if (!linktype && typeof link === 'string') return link;
  if (linktype === 'story') return fixStoryPath(cached_url);

  return url;
};

export function fixStoryPath(path) {
  let storyPath = rewriteStorySlugs[path] || path;

  if (!storyPath.startsWith('/')) storyPath = `/${storyPath}`;
  // if (!storyPath.endsWith('/')) storyPath = `${storyPath}/`;

  return storyPath;
};

export function getPathWithoutLocale(path, locale, defaultLocale) {
  if (!path && !locale && !defaultLocale) return path;

  const str = `/${locale}/`;

  if (locale !== defaultLocale && path.startsWith(str)) {
    return `/${path.slice(str.length)}`;
  }

  return path;
};

export function isSbAnyLinkFilled(link) {
  const { url } = link;
  const storyLink = isSbInternalLinkFilled(link);

  return !!(link && (storyLink || url));
};

export function isSbInternalLinkFilled(link) {
  const { linktype, cached_url } = link;
  const storyLink = linktype === 'story' && cached_url && cached_url !== '/en/';

  return !!storyLink;
};

export function getExtensionFromString(str) {
  if (!str) return '';

  return str.split(/[#?]/)?.[0]?.split('.')?.pop()?.trim().toLowerCase() || '';
};

export function isRichtextFilled(doc) {
  const emptyRichtext = {
    "type": "doc",
    "content": [{ "type": "paragraph" }]
  };

  return doc ? !isEqual(doc, emptyRichtext) : false;
};

export function serialize(obj) {
  const str = [];

  for (const p in obj) {
    if (obj.hasOwnProperty(p) && typeof obj[p] !== 'undefined') {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }

  return str.join('&');
}
