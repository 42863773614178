//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseSpinner',
  props: {
    strokeWidth: {
      type: [Number,String],
      default: 4
    }
  },
}
